import axios from 'axios'
const baseUrl = 'https://node-be.diegomro.awsps.myinstance.com/persons'

const getAll = async () => {
    const request = axios.get(baseUrl)
    const response = await request
    return response.data
}

const create = async newObject => {
    const request = axios.post(baseUrl, newObject)
    const response = await request
    return response.data
}

const update = async (id,newObject) => {
    const request = axios.put(`${baseUrl}/${id}`,newObject)
    const response = await request
    return response.data
}

const deleteObj = async id => {
    const request = axios.delete(`${baseUrl}/${id}`)
    const response = await request
    return response.data
}

const personService = { getAll, create, update, deleteObj }
export default personService